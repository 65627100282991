<template>
  <div class="app-container">
    <div class="messagebox">
      <div class="titlebox"><span>{{ headName }}</span>{{ tablelist.WishName }}</div>
      <span style="font-weight: bold">{{ tablelist.ProvinceName }}</span>
      <span>我的成绩：{{ tablelist.Score }}分</span>
      <span>{{ tablelist.RankLow }}位</span>
        <span v-for="(item, index) in tablelist.SelectSubjectList" :key="index"
          >{{ item.SelectExamTitle ? item.SelectExamTitle + ":" : "" }}
          {{ item.SelectExamSubject.join(",") + (index != tablelist.SelectSubjectList.length-1?"；":" ") }}</span
        >
      <span>{{ tablelist.BatchName }}</span>
      <span>
        <el-button size="mini" v-if="showPrint" @click="printPage"
          >打印</el-button
        >
      </span>
    </div>
    <template>
      <el-table
        :data="tablelist.WishRecord"
        style="width: 100%"
        :header-cell-style="{ background: '#f5f5f5', color: '#606266' }"
      >
        <el-table-column
          prop="WishCode"
          label="序号"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <div class="WishCode">{{ scope.row.WishCode }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="SchoolName" label="院校">
          <template slot-scope="scope">
            <div>
              <span
                class="RankColor SchoolNamebox"
                @click="gotoSchoolDetail(scope.row.SchoolId)"
                >{{ scope.row.SchoolName }}</span
              >

              <template v-if="scope.row.TagNames">
                <el-tag
                  style="margin-left: 10px"
                  size="mini"
                  v-for="item in scope.row.TagNames.split(',')"
                  :key="item"
                  >{{ item }}</el-tag
                >
              </template>
            </div>
            <div class="color666">
              <!-- <div>{{scope.row.CategoryNames}}/{{scope.row.SchoolNature}}/{{scope.row.ProvinceName}}</div> -->
              <span>代码 {{ scope.row.SchoolCode }} </span>
            </div>
            <div v-if="scope.row.Ranking">
              <el-tag size="mini">{{ scope.row.Ranking + '['+scope.row.RankingType+']' }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="SpecialtyName" label="专业">
          <template slot-scope="scope">
            <div>
              <span
                class="RankColor SchoolNamebox"
                @click="
                  gotoSpecialtyDetail(
                    scope.row.SpecialtyCode,
                    scope.row.SpecialtyName
                  )
                "
                >{{ scope.row.SpecialtyName }}</span
              >
            </div>
            <div class="color666">代码 {{ scope.row.SpecialtyEnrollmentCode }}
              <span v-if="tablelist.WishType === 0">录取概率： {{ scope.row.EnrollmentPercent }}%</span>
              <span v-if="tablelist.WishType === 1">预测分： {{ scope.row.EnrollmentPercent }}</span>
            </div>
            <div>
              <el-tag v-if="scope.row.LearnSubjectAssessment" size="small">学科评估：{{ scope.row.LearnSubjectAssessment }}</el-tag>
              <el-tag v-if="scope.row.SpecialtyRank" size="small">专业排名：{{ scope.row.SpecialtyRank }}</el-tag>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </template>
     <!-- <div class="signature">
      <div>专家确认签字：</div> <div>日期：</div> <div>客户确认签字：</div> <div>日期：</div>
    </div> -->
    <div class="fillin-instruction mb20">
      <p class="footer-notes"><span class="footer-notes-tag">注</span>：以上志愿表为模拟填报志愿表，正式填报请登录省考试院指定填报网站。</p>
      <p class="footer-apply">
        申明：使用当年招生计划和参考历年录取数据的志愿模拟填报平台，参考数据均来自省考试院，正式填报过程中如遇到数据错误，请参阅省考试院发布的相关招生计划书籍。
      </p>
    </div>
  </div>
</template>
<script>
import API from '@/api/config'
// import { WishSpecialtyGroupDetail } from "@/api/newEntrance";
export default {
  name:'newexamdetail',
  data() {
    return {
      tablelist: [],
      showPrint: true,

      headName: ''
    };
  },
  created() {
    this.getTableList();

    this.initialize()
  },
  methods: {

    initialize() {
      this.$getStorage("userData").then(res => {
        this.headName = res.NickName
      })
    },

    getTableList() {
      if (this.$route.query.WishId) {
        API.User.WishSpecialtySchoolDetail({
          WishId: this.$route.query.WishId,
        }).then((res) => {
          if (res.data) {
            this.tablelist = res.data;
            // this.printPage();
          }
        });
      }
    },
    printPage() {
      this.showPrint = false;
      setTimeout(() => {
        window.print();
        this.showPrint = true;
      }, 300);
    },
    // 院校详情
    gotoSchoolDetail(ID) {
      let routerUrl = this.$router.resolve({
        path: "/university-detail",
        query: { id: ID },
      });
      window.open(routerUrl.href, "_blank");
    },
    // 专业详情
    gotoSpecialtyDetail(ID, Name) {
      let routerUrl = {};
      if (ID && ID.length === 6) {
        routerUrl = this.$router.resolve({
          path: "/specialty-detail",
          query: { SpecialtyCode: ID },
        });
      } else {
        routerUrl = this.$router.resolve({
          path: "/specialty-list",
          query: { CategoryCode: ID, CategoryName: Name },
        });
      }

      window.open(routerUrl.href, "_blank");
    },
  },
};
</script>
<style lang="less" scoped>
.app-container {
  width: 1060px;
  margin: auto;
}
.messagebox {
  line-height: 2;
  margin-top: 40px;
   .titlebox{
    font-size: 20px;
     & > span {
    display: inline-block;
    margin-right: 24px;
  }
  }
  & > span {
    display: inline-block;
    margin-right: 24px;
  }
}
.WishCode {
  font-size: 20px;
  color: rgba(var(--themecolor),1);
}
.RankColor {
  font-size: 18px;
}
.color666 {
  color: #666;
}
.SchoolNamebox {
  cursor: pointer;
}
.printHideCss {
  display: none !important;
}
.signature{
  margin: 50px 0px 20px;
  display: flex;
  justify-content: space-between;
  div{
    width: 25%;
  }
}
 .footer-notes {
    font-size: 16px;
    color: #4484f5;

    .footer-notes-tag {
      color: var(--color-red);
      font-size: 16px;
    }
  }
</style>
